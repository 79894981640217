<template>
  <v-container v-if='LOGGED_IN' class="py-0">
    <v-row justify='center'><v-col cols=12 xl=10>

    <v-card class="primary" dark>
      <v-tabs centered icons-and-text fixed-tabs hidxe-slider show-arrows
        background-color='transparent'
        v-model='tab'
        class='flex-grow-0'
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab key="uninvoiced">To Invoice<v-icon>mdi-briefcase-variant</v-icon></v-tab>
        <v-tab key="drafts">Drafts<v-icon>mdi-file-outline</v-icon></v-tab>
        <v-tab key="batches">Invoices<v-icon>mdi-file-check</v-icon></v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab" class="transparent overflow-visible">
      <v-tab-item key="uninvoiced">
        <Uninvoiced ref="uninvoiced" @draft="refreshDrafts" />
      </v-tab-item>

      <v-tab-item key="drafts">
        <Drafts ref="drafts" @undraft="refreshUninvoiced" @batch="refreshBatches" />
      </v-tab-item>

      <v-tab-item key="batches">
        <Batches ref="batches" />
      </v-tab-item>

    </v-tabs-items>
    </v-col></v-row>
  </v-container>
</template>

<style lang='scss' >
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import Uninvoiced from './Uninvoiced'
import Drafts from './Drafts'
import Batches from './Batches'
export default {
  name: 'Invoices',

  components: {
    Uninvoiced, Drafts, Batches
  },

  data: () => ({
    tab: null,
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),
  },
  watch: {
  },
  methods:{
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('UI', ['SHOW_NAVBARS', 'SET_PAGE_TITLE']),
    ...mapActions('API', ['API']),

    refreshDrafts(){ this.$refs.drafts.refresh(); },

    refreshUninvoiced(){ this.$refs.uninvoiced.refresh(); },

    refreshBatches(){ this.$refs.batches.refresh(); },

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE('Invoices');
    });
  }
};
</script>
