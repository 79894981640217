<template>
  <div class="my-4">

    <v-card class="mt-4" :loading='loading' :disabled='loading'>
      <v-card-text class="pt-4 pb-1">

        <v-row class="">
          <v-col cols="12" md="4" lg="3">
            <v-text-field outlined rounded dense hide-details placeholder='search' prepend-icon="mdi-magnify" class="mb-4" v-model="search" />
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :options.sync="options"
          :items="items" item-key='xid'
          :search="search"
          show-select
          hide-default-footer
          v-model='selection' 
        >
          <template #item.ship_to="{item}">
            <span v-if="item.ship_to" class="text-caption">
              {{item.ship_to.company_name}}<br/>
              {{item.ship_to.city_locality}}&nbsp;{{item.ship_to.state_province}}&nbsp;{{item.ship_to.postal_code}}
            </span>
          </template>
        </v-data-table>
        <p class='mt-4 text-right'>
          <v-btn large outlined color='primary' @click='doNotInvoice' :disabled='!selection.length' class="mr-4"><v-icon class="mr-2">mdi-close-circle-outline</v-icon> Skip</v-btn>
          <v-btn large color='primary' @click='draft' :disabled='!selection.length'>Draft ({{selection.length}})</v-btn>
        </p>

      </v-card-text>
    </v-card>
  </div>
</template>

<style lang='scss' >
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Uninvoiced',

  components: {
  },

  data: () => ({
    loading: false,
    uninvoiced: [],
    selection: [],
    headers: [
      { text: 'Job', value: 'ref' },
      { text: 'Description', value: 'description', },
      { text: 'Customer', value: '_customer' },
      { text: 'Location', value: 'ship_to' },
      { text: 'Despatch', value: '_dispatch_status', filterable: false },
    ],
    options: {
      page: 1, sortBy: ['_customer'], sortDesc: [], itemsPerPage: -1
    },
    search: "",
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),

    items(){
      return this.uninvoiced.map(j => ({
        ...j,
        _customer: j.customer?.name ?? '',
        _contact: `${j.contact?.user?.name_first ?? ''} ${j.contact?.user?.name_last ?? ''}`,
        _location: j.ship_to?.company_name ?? '',
        _dispatch_status: j.dispatch_status?.status ?? ''
      }));
    }

  },
  watch: {
  },
  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['CONFIRM']),

    async loadUninvoiced(){
      this.loading = true;
      this.uninvoiced = await this.API({ method: 'GET', endpoint: 'billing/uninvoiced' });
      this.loading = false;
    },

    async draft(){
      let data = { jobs: this.selection.map(j => j.xid) };
      this.loading = true;
      await this.API({ method: 'POST', endpoint: 'billing/uninvoiced/draft', data });
      this.selection = [];
      this.$emit('draft');
      this.loadUninvoiced();
    },

    async doNotInvoice(){
      let jobs = this.selection.map(j => j.xid);
      await this.CONFIRM({ title: 'Skip Invoicing?', message: `Skip invoicing for the selected jobs?` });
      this.loading = true;
      let statuses = await this.API({ method: 'GET', endpoint: 'billing/status' });
      let payment_status = statuses.find(s=>s.status == "Not Required")?.xid ?? null;
      for(let i=0; i<jobs.length; i++){
        await this.API({ method: 'PATCH', endpoint: `jobs/${jobs[i]}`, data: { payment_status } });
      }
      this.selection = [];
      this.loadUninvoiced();
    },

    refresh(){
      this.loadUninvoiced();
    },

  },

  mounted(){
    this.refresh();
  }

};
</script>
