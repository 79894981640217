var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-4"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', {
    staticClass: "pt-0 pb-1"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.drafts,
      "items-per-page": -1,
      "item-key": "xid",
      "show-select": "",
      "hide-default-footer": "",
      "show-expand": "",
      "single-expand": "",
      "expanded": _vm.expandedRows
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expandedRows = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.customer.name",
      fn: function fn(_ref) {
        var item = _ref.item,
          value = _ref.value;
        return [_c('a', {
          attrs: {
            "title": "Open Customer in New Tab"
          },
          on: {
            "click": function click($event) {
              return _vm.openCustomerInNewTab(item.customer);
            }
          }
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: "item.location",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.location ? _c('span', {
          staticClass: "text-caption"
        }, [_vm._v(" " + _vm._s(item.location.company_name)), _c('br'), _vm._v(" " + _vm._s(item.location.city_locality) + " " + _vm._s(item.location.state_province) + " " + _vm._s(item.location.postal_code) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.jobs",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.jobs.length) + " ")];
      }
    }, {
      key: "item.subtotal",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(_vm._s((value / 100).toFixed(2)))];
      }
    }, {
      key: "item.tax",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(_vm._s((value / 100).toFixed(2)))];
      }
    }, {
      key: "item.due_days",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          value = _ref6.value;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.editDueDays(item);
            }
          }
        }, [_vm._v(_vm._s(value) + " days")])];
      }
    }, {
      key: "item._actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "title": "Edit Comments",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editInvoiceComments(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-message-text")])], 1), _c('v-btn', {
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "title": "View Draft Invoice",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.previewInvoice(item.xid);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref8) {
        var headers = _ref8.headers;
        return [_c('td'), _c('td', {
          staticClass: "pa-2",
          attrs: {
            "colspan": headers.length - 2
          }
        }, [_c('v-data-table', {
          staticClass: "transparent",
          attrs: {
            "headers": _vm.jobHeaders,
            "items": _vm.jobs,
            "items-per-page": -1,
            "item-key": "xid",
            "loading": _vm.loading,
            "hide-default-footer": ""
          },
          scopedSlots: _vm._u([{
            key: "item.ref",
            fn: function fn(_ref9) {
              var item = _ref9.item,
                value = _ref9.value;
              return [_c('a', {
                attrs: {
                  "title": "Open Job in New Tab"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openJobInNewTab(item);
                  }
                }
              }, [_vm._v(_vm._s(value))])];
            }
          }, {
            key: "item._value",
            fn: function fn(_ref10) {
              var item = _ref10.item;
              return [_vm._v(_vm._s((item.subtotal / 100).toFixed(2)))];
            }
          }, {
            key: "item.subtotal_discount",
            fn: function fn(_ref11) {
              var value = _ref11.value;
              return [_vm._v(_vm._s((value / 100).toFixed(2)))];
            }
          }, {
            key: "item._subtotal",
            fn: function fn(_ref12) {
              var item = _ref12.item;
              return [_vm._v(_vm._s(((item.subtotal - item.subtotal_discount) / 100).toFixed(2)))];
            }
          }, {
            key: "item._tax",
            fn: function fn(_ref13) {
              var item = _ref13.item;
              return [_vm._v(_vm._s(((item.tax - item.tax_discount) / 100).toFixed(2)))];
            }
          }, {
            key: "item._actions",
            fn: function fn(_ref14) {
              var item = _ref14.item;
              return [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": "primary",
                  "title": "Line Items"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewLineItems(item);
                  }
                }
              }, [_vm._v("mdi-view-list")]), _c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": item.discount ? "primary" : "secondary",
                  "title": "Customer Discount"
                },
                on: {
                  "click": function click($event) {
                    return _vm.toggleCustomerDiscount(item);
                  }
                }
              }, [_vm._v("mdi-brightness-percent")]), _c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": "red",
                  "title": "Remove from Draft Invoice"
                },
                on: {
                  "click": function click($event) {
                    return _vm.undraft(item);
                  }
                }
              }, [_vm._v("mdi-trash-can")])];
            }
          }], null, true)
        })], 1)];
      }
    }]),
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }), _c('p', {
    staticClass: "mt-4 text-right"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary",
      "disabled": !_vm.selection.length
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v("Send (" + _vm._s(_vm.selection.length) + ")")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "scrollable": ""
    },
    model: {
      value: _vm.lineItems.show,
      callback: function callback($$v) {
        _vm.$set(_vm.lineItems, "show", $$v);
      },
      expression: "lineItems.show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "py-2 primary white--text"
  }, [_vm._v(_vm._s(_vm.lineItems.jobRef))]), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "height": "50vh"
    }
  }, [_vm.lineItems.show ? [_c('JobLineitems', {
    attrs: {
      "jobId": _vm.lineItems.jobId
    }
  })] : _vm._e()], 2), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-3"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.closeLineItems
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }