var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "dark": ""
    }
  }, [_c('v-tabs', {
    staticClass: "flex-grow-0",
    attrs: {
      "centered": "",
      "icons-and-text": "",
      "fixed-tabs": "",
      "hidxe-slider": "",
      "show-arrows": "",
      "background-color": "transparent"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "white"
    }
  }), _c('v-tab', {
    key: "uninvoiced"
  }, [_vm._v("To Invoice"), _c('v-icon', [_vm._v("mdi-briefcase-variant")])], 1), _c('v-tab', {
    key: "drafts"
  }, [_vm._v("Drafts"), _c('v-icon', [_vm._v("mdi-file-outline")])], 1), _c('v-tab', {
    key: "batches"
  }, [_vm._v("Invoices"), _c('v-icon', [_vm._v("mdi-file-check")])], 1)], 1)], 1), _c('v-tabs-items', {
    staticClass: "transparent overflow-visible",
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: "uninvoiced"
  }, [_c('Uninvoiced', {
    ref: "uninvoiced",
    on: {
      "draft": _vm.refreshDrafts
    }
  })], 1), _c('v-tab-item', {
    key: "drafts"
  }, [_c('Drafts', {
    ref: "drafts",
    on: {
      "undraft": _vm.refreshUninvoiced,
      "batch": _vm.refreshBatches
    }
  })], 1), _c('v-tab-item', {
    key: "batches"
  }, [_c('Batches', {
    ref: "batches"
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }