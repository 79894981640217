var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-4"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', {
    staticClass: "pt-0 pb-1"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.batches,
      "items-per-page": -1,
      "item-key": "xid",
      "show-select": "",
      "hide-default-footer": "",
      "show-expand": "",
      "single-expand": "",
      "expanded": _vm.expandedRows
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expandedRows = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.created_at",
      fn: function fn(_ref) {
        var item = _ref.item,
          value = _ref.value;
        return [_c('Moment', {
          staticClass: "font-weight-medium",
          attrs: {
            "timestamp": value,
            "mode": "datetime"
          }
        })];
      }
    }, {
      key: "item.jobs",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.jobs.length) + " ")];
      }
    }, {
      key: "item._actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.ready ? [_c('v-menu', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "title": "Export"
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-download")])];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-subheader', [_vm._v("Download")]), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.batch_summary_pdf(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-4",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-file-document")]), _vm._v("PDF ")], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.batch_summary_myob(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-4",
          attrs: {
            "color": "purple"
          }
        }, [_vm._v("mdi-file-table")]), _vm._v("MYOB (AccountRight) ")], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.batch_summary_xero(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-4",
          attrs: {
            "color": "blue"
          }
        }, [_vm._v("mdi-file-table")]), _vm._v("Xero ")], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.batch_summary_zoho(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-4",
          attrs: {
            "color": "blue"
          }
        }, [_vm._v("mdi-file-table")]), _vm._v("Zoho Books ")], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.batch_zip(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-4",
          attrs: {
            "color": "orange"
          }
        }, [_vm._v("mdi-folder-zip")]), _vm._v("Zip Archive ")], 1)], 1)], 1)] : _c('v-chip', {
          staticClass: "warning",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("Processing")])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref5) {
        var headers = _ref5.headers;
        return [_c('td'), _c('td', {
          staticClass: "pa-2",
          attrs: {
            "colspan": headers.length - 1
          }
        }, [_c('v-data-table', {
          staticClass: "transparent",
          attrs: {
            "headers": _vm.invoiceHeaders,
            "items": _vm.invoices,
            "items-per-page": -1,
            "item-key": "xid",
            "loading": _vm.loading,
            "hide-default-footer": ""
          },
          scopedSlots: _vm._u([{
            key: "item.customer",
            fn: function fn(_ref6) {
              var value = _ref6.value;
              return [_c('a', {
                attrs: {
                  "title": "Open Customer in New Tab"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openCustomerInNewTab(value);
                  }
                }
              }, [_vm._v(_vm._s(value.name))])];
            }
          }, {
            key: "item.location",
            fn: function fn(_ref7) {
              var value = _ref7.value;
              return [value ? _c('span', {
                staticClass: "text-caption"
              }, [_vm._v(" " + _vm._s(value.company_name)), _c('br'), _vm._v(" " + _vm._s(value.city_locality) + " " + _vm._s(value.state_province) + " " + _vm._s(value.postal_code) + " ")]) : _vm._e()];
            }
          }, {
            key: "item.subtotal",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_vm._v(_vm._s((item.subtotal / 100).toFixed(2)))];
            }
          }, {
            key: "item.tax",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return [_vm._v(_vm._s((item.tax / 100).toFixed(2)))];
            }
          }, {
            key: "item.total",
            fn: function fn(_ref10) {
              var item = _ref10.item;
              return [_vm._v(_vm._s((item.total / 100).toFixed(2)))];
            }
          }, {
            key: "item._actions",
            fn: function fn(_ref11) {
              var item = _ref11.item;
              return [_c('v-btn', {
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "title": "View Invoice",
                  "color": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewInvoice(item);
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1), _c('v-btn', {
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "title": "Download Invoice",
                  "color": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.downloadInvoice(item);
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-download")])], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }]),
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }