<template>
  <div class="my-4">

    <v-card class="mt-4" :loading='loading' :disabled='loading'>
      <v-card-text class="pt-0 pb-1">

        <v-data-table
          :headers="headers"
          :items="batches" :items-per-page="-1" item-key='xid'
          show-select
          hide-default-footer
          v-model='selection'
          show-expand single-expand :expanded.sync="expandedRows"
        >
          <template #item.created_at="{item,value}">
            <Moment :timestamp="value" mode="datetime" class="font-weight-medium" />
          </template>
          <template #item.jobs="{item}">
            {{item.jobs.length}}
          </template>
          <template #item._actions="{item}">
            <template v-if="item.ready">
              <v-menu>
                <template #activator="{on, attrs}">
                  <v-icon v-on='on' v-bind='attrs' color="primary" title="Export">mdi-download</v-icon>
                </template>
                <v-list dense>
                  <v-subheader>Download</v-subheader>
                  <v-list-item link  @click="batch_summary_pdf(item)">
                    <v-icon color="red" class="mr-4">mdi-file-document</v-icon>PDF
                  </v-list-item>
                  <v-list-item link  @click="batch_summary_myob(item)">
                    <v-icon color="purple" class="mr-4">mdi-file-table</v-icon>MYOB (AccountRight)
                  </v-list-item>
                  <v-list-item link  @click="batch_summary_xero(item)">
                    <v-icon color="blue" class="mr-4">mdi-file-table</v-icon>Xero
                  </v-list-item>
                  <v-list-item link  @click="batch_summary_zoho(item)">
                    <v-icon color="blue" class="mr-4">mdi-file-table</v-icon>Zoho Books
                  </v-list-item>
                  <v-list-item link  @click="batch_zip(item)">
                    <v-icon color="orange" class="mr-4">mdi-folder-zip</v-icon>Zip Archive
                  </v-list-item>
                </v-list>
              </v-menu>
              
            </template>
            <v-chip v-else label small class="warning">Processing</v-chip>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td></td>
            <td :colspan="headers.length-1" class='pa-2'>
              <v-data-table
                :headers='invoiceHeaders'
                :items='invoices' :items-per-page='-1' item-key='xid'
                :loading='loading'
                hide-default-footer
                class='transparent'
              >
                <template #item.customer="{value}"><a @click='openCustomerInNewTab(value)' title='Open Customer in New Tab'>{{value.name}}</a></template>
                <template #item.location="{value}">
                  <span v-if="value" class="text-caption">
                    {{value.company_name}}<br/>
                    {{value.city_locality}}&nbsp;{{value.state_province}}&nbsp;{{value.postal_code}}
                  </span>
                </template>
                <template #item.subtotal="{item}">{{((item.subtotal)/100).toFixed(2)}}</template>
                <template #item.tax="{item}">{{((item.tax)/100).toFixed(2)}}</template>
                <template #item.total="{item}">{{((item.total)/100).toFixed(2)}}</template>
                <template #item._actions="{item}">
                  <v-btn fab small text title='View Invoice' color='primary' @click="viewInvoice(item)"><v-icon>mdi-open-in-new</v-icon></v-btn>
                  <v-btn fab small text title='Download Invoice' color='primary' @click="downloadInvoice(item)"><v-icon>mdi-download</v-icon></v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>

  </div>
</template>

<style lang='scss' >
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import Moment from '@/components/Moment';
import MomentJS from 'moment';
import DownloadFile from 'downloadjs';

export default {
  name: 'Batches',

  components: {
    Moment,
  },

  data: () => ({
    loading: false,
    batches: [],
    invoices: [],
    selection: [],
    expandedRows: [],
    headers: [
      { text: 'Date / Time', value: 'created_at' },
      { text: 'No. of Invoices', value: 'invoice_count' },
      { text: 'Actions', value: '_actions' },
    ],
    invoiceHeaders: [
      { text: 'Invoice', value:'ref' },
      { text: 'Customer', value:'customer' },
      { text: 'Location', value:'location' },
      { text: 'Sub.', value:'subtotal' },
      { text: 'Tax', value:'tax' },
      { text: 'Total', value:'total' },
      { text: 'Actions', value:'_actions' },
    ],
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),
    expandedBatchId(){ return this.expandedRows?.[0]?.xid; },
  },
  watch: {
    expandedBatchId(xid){ if(xid) { this.jobs = []; this.loadBatchInvoices(); } },
  },
  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('PRINTING', ['PREVIEW']),
    ...mapActions('UI', ['CONFIRM']),

    async loadBatches(){
      this.loading = true;
      this.batches = await this.API({ method: 'GET', endpoint: 'billing/batches' });
      this.loading = false;
    },

    async loadBatchInvoices(){
      this.loading = true;
      this.invoices = await this.API({ method: 'GET', endpoint: `billing/batches/${this.expandedBatchId}/invoices` });
      this.loading = false;
    },

    openCustomerInNewTab({xid}){ window.open(`#/customer/${xid}`, "_blank"); },

    async viewInvoice(invoice){
      this.loading = true;
      let invoice_pdf = await this.API({ method: 'GET', endpoint: `billing/invoices/${invoice.xid}/pdf` });
      this.PREVIEW(invoice_pdf);
      this.loading = false;
    },

    async downloadInvoice(invoice){
      window.open(`${window.app.config.api_base_url}/file/${invoice.pdf.xid}/${invoice.pdf.check_sha256}`, '_blank');
    },

    refresh(){
      this.loadBatches();
    },

    async batch_summary_pdf(batch){
      let { xid, created_at } = batch;
      let pdf = await this.API({ method: 'GET', endpoint: `billing/batches/${xid}/export_pdf` });
      let date = MomentJS(created_at).format("YYYY-MM-DD HHmm");
      DownloadFile(new Blob([Uint8Array.from(atob(pdf), c => c.charCodeAt(0))]), `Invoices ${date}.pdf`);
    },

    async batch_summary_myob(batch){
      let { xid, created_at } = batch;
      let pdf = await this.API({ method: 'GET', endpoint: `billing/batches/${xid}/export_myob_accountright` });
      let date = MomentJS(created_at).format("YYYY-MM-DD HHmm");
      DownloadFile(new Blob([Uint8Array.from(atob(pdf), c => c.charCodeAt(0))]), `Invoices ${date}.csv`);
    },

    async batch_summary_zoho(batch){
      let { xid, created_at } = batch;
      let pdf = await this.API({ method: 'GET', endpoint: `billing/batches/${xid}/export_zoho_books` });
      let date = MomentJS(created_at).format("YYYY-MM-DD HHmm");
      DownloadFile(new Blob([Uint8Array.from(atob(pdf), c => c.charCodeAt(0))]), `Invoices ${date}.csv`);
    },

    async batch_summary_xero(batch){
      let { xid, created_at } = batch;
      let pdf = await this.API({ method: 'GET', endpoint: `billing/batches/${xid}/export_xero` });
      let date = MomentJS(created_at).format("YYYY-MM-DD HHmm");
      DownloadFile(new Blob([Uint8Array.from(atob(pdf), c => c.charCodeAt(0))]), `Invoices ${date}.csv`);
    },

    async batch_zip(batch){
      let { xid, created_at } = batch;
      let pdf = await this.API({ method: 'GET', endpoint: `billing/batches/${xid}/export_zip` });
      let date = MomentJS(created_at).format("YYYY-MM-DD HHmm");
      DownloadFile(new Blob([Uint8Array.from(atob(pdf), c => c.charCodeAt(0))]), `Invoices ${date}.zip`);
    }

  },

  mounted(){
    this.refresh();
  }

};
</script>
