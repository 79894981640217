<template>
  <div class="my-4">

    <v-card class="mt-4" :loading='loading' :disabled='loading'>
      <v-card-text class="pt-0 pb-1">

        <v-data-table
          :headers="headers"
          :items="drafts" :items-per-page="-1" item-key='xid'
          show-select
          hide-default-footer
          v-model='selection'
          show-expand single-expand :expanded.sync="expandedRows"
        >
          <template #item.customer.name="{item,value}"><a @click='openCustomerInNewTab(item.customer)' title='Open Customer in New Tab'>{{value}}</a></template>
          <template #item.location="{item}">
            <span v-if="item.location" class="text-caption">
              {{item.location.company_name}}<br/>
              {{item.location.city_locality}}&nbsp;{{item.location.state_province}}&nbsp;{{item.location.postal_code}}
            </span>
          </template>
          <template #item.jobs="{item}">
            {{item.jobs.length}}
          </template>
          <template #item.subtotal="{value}">{{(value/100).toFixed(2)}}</template>
          <template #item.tax="{value}">{{(value/100).toFixed(2)}}</template>
          <template #item.due_days="{item, value}"><a @click="editDueDays(item)">{{value}} days</a></template>
          <template #item._actions="{item}">
            <v-btn fab small text title='Edit Comments' color='primary' @click="editInvoiceComments(item)"><v-icon>mdi-message-text</v-icon></v-btn>
            <v-btn fab small text title='View Draft Invoice' color='primary' @click="previewInvoice(item.xid)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td></td>
            <td :colspan="headers.length-2" class='pa-2'>
              <v-data-table
                :headers='jobHeaders'
                :items='jobs' :items-per-page='-1' item-key='xid'
                :loading='loading'
                hide-default-footer
                class='transparent'
              >
                <template #item.ref="{item,value}"><a @click='openJobInNewTab(item)' title='Open Job in New Tab'>{{value}}</a></template>
                <template #item._value="{item}">{{((item.subtotal)/100).toFixed(2)}}</template>
                <template #item.subtotal_discount="{value}">{{(value/100).toFixed(2)}}</template>
                <template #item._subtotal="{item}">{{((item.subtotal - item.subtotal_discount)/100).toFixed(2)}}</template>
                <template #item._tax="{item}">{{((item.tax - item.tax_discount)/100).toFixed(2)}}</template>
                <template #item._actions="{item}">
                  <v-icon color='primary' @click='viewLineItems(item)' title='Line Items' class='mr-2'>mdi-view-list</v-icon>
                  <v-icon :color='item.discount?"primary":"secondary"' @click='toggleCustomerDiscount(item)' title='Customer Discount' class='mr-2'>mdi-brightness-percent</v-icon>
                  <v-icon color="red" @click='undraft(item)' title='Remove from Draft Invoice' class='mr-2'>mdi-trash-can</v-icon>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <p class='mt-4 text-right'>
          <v-btn large color='primary' @click='send' :disabled='!selection.length'>Send ({{selection.length}})</v-btn>
        </p>

      </v-card-text>
    </v-card>

    <v-dialog v-model='lineItems.show' max-width='600px' scrollable>
      <v-card>
        <v-card-title class='py-2 primary white--text'>{{lineItems.jobRef}}</v-card-title>
        <v-card-text class='pt-4' style='height:50vh'>
          <template  v-if='lineItems.show'>
            <JobLineitems :jobId='lineItems.jobId' />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-3'>
          <v-spacer />
          <v-btn class='primary' @click='closeLineItems'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang='scss' >
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import JobLineitems from '@/components/JobLineitems/JobLineitems'

export default {
  name: 'Drafts',

  components: {
    JobLineitems,
  },

  data: () => ({
    loading: false,
    drafts: [],
    jobs: [],
    selection: [],
    expandedRows: [],
    headers: [
      { text: 'Customer', value: 'customer.name' },
      { text: 'Location', value: 'location' },
      { text: 'Jobs', value: 'jobs' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: 'Tax', value: 'tax' },
      { text: 'Due', value: 'due_days' },
      { text: '', value: '_actions' },
      { text: '', value: 'data-table-expand' },
    ],
    jobHeaders: [
      { text: 'Job', value:'ref' },
      { text: 'Description', value: 'description' },
      { text: 'Value', value: '_value' },
      { text: 'Discount', value: 'subtotal_discount' },
      { text: 'Subtotal', value: '_subtotal' },
      { text: 'Tax', value: '_tax' },
      { text: 'Actions', value: '_actions', align: 'right' }
    ],
    lineItems: {
      show: false,
      jobId: null,
      jobRef: null,
    }
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),
    expandedDraftId(){ return this.expandedRows?.[0]?.xid; },
  },
  watch: {
    expandedDraftId(xid){ if(xid) { this.jobs = []; this.loadDraftJobs(); } },
  },
  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('PRINTING', ['PREVIEW']),
    ...mapActions('UI', ['CONFIRM', 'PROMPT', 'PROMPT_TEXTAREA']),

    loadDrafts(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'billing/drafts' })
      .then(drafts => { this.drafts = drafts } )
      .finally(() => { this.loading = false });
    },

    loadDraftJobs(){
      if(!this.drafts.map(d => d.xid).includes(this.expandedDraftId)) return; // job deleted? ..skip
      if(!this.expandedDraftId) return; // no jobs listing expanded? ..skip
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `billing/drafts/${this.expandedDraftId}/jobs` })
      .then(jobs => { this.jobs = jobs } )
      .finally(() => { this.loading = false });
    },

    openJobInNewTab({xid}){ window.open(`#/job/${xid}`, "_blank"); },
    openCustomerInNewTab({xid}){ window.open(`#/customer/${xid}`, "_blank"); },

    async toggleCustomerDiscount({xid, discount}){
      let data = { discount: !discount };
      this.loading = true;
      await this.API({ method: 'PATCH', endpoint: `jobs/${xid}`, data });
      this.loadDraftJobs();
    },

    viewLineItems({xid, ref}){
      this.lineItems.jobId = xid;
      this.lineItems.show = true;
      this.lineItems.jobRef = ref;
    },

    closeLineItems(){
      this.lineItems.show = false;
      this.lineItems.jobId = null;
      this.loadDraftJobs();
    },

    async previewInvoice(invoiceId){
      this.loading = true;
      let invoice_pdf = await this.API({ method: 'GET', endpoint: `billing/invoices/${invoiceId}/pdf` });
      this.PREVIEW(invoice_pdf);
      this.loading = false;
    },

    async send(){
      //await this.CONFIRM({ title: "Send Invoices?", message: "Invoices with be finalised and sent.\nThis action cannot be reversed." });
      let data = { invoices: this.selection.map(inv => inv.xid) };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `billing/finalise`, data })
      .then(() => {
        this.selection = [];
        this.refresh();
        this.$emit('batch');
      })
      .finally(() => { this.loading = false; });
    },

    undraft(job){
      this.loading = true;
      this.API({ method: 'DELETE', endpoint: `billing/drafts/${this.expandedDraftId}/jobs/${job.xid}` })
      .then(() => {
        this.refresh();
        this.$emit('undraft');
      })
      .catch(() => { this.loading = false; });
    },

    refresh(){
      this.loadDrafts()
      .then(this.loadDraftJobs);
    },

    async editDueDays(item){
      let due_days = await this.PROMPT({ title: 'Due Date', message: 'Payment due in days after invoice is generated:', placeholder: item.due_days });
      this.patchDraft(item, { due_days });
    },

    async editInvoiceComments(item){
      console.log(item);
      let comments = await this.PROMPT_TEXTAREA({ title: 'Comments', message: 'Comment displayed at top of invoice:', placeholder: item.comments });
      this.patchDraft(item, { comments });
    },

    patchDraft(invoice, data){
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `/billing/drafts/${invoice.xid}`, data })
      .then(this.refresh)
      .catch(() => { this.loading = false; });
    }


  },

  mounted(){
    this.refresh();
  }

};
</script>
