var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-4"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', {
    staticClass: "pt-4 pb-1"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-4",
    attrs: {
      "outlined": "",
      "rounded": "",
      "dense": "",
      "hide-details": "",
      "placeholder": "search",
      "prepend-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "options": _vm.options,
      "items": _vm.items,
      "item-key": "xid",
      "search": _vm.search,
      "show-select": "",
      "hide-default-footer": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.ship_to",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.ship_to ? _c('span', {
          staticClass: "text-caption"
        }, [_vm._v(" " + _vm._s(item.ship_to.company_name)), _c('br'), _vm._v(" " + _vm._s(item.ship_to.city_locality) + " " + _vm._s(item.ship_to.state_province) + " " + _vm._s(item.ship_to.postal_code) + " ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }), _c('p', {
    staticClass: "mt-4 text-right"
  }, [_c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary",
      "disabled": !_vm.selection.length
    },
    on: {
      "click": _vm.doNotInvoice
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-close-circle-outline")]), _vm._v(" Skip")], 1), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary",
      "disabled": !_vm.selection.length
    },
    on: {
      "click": _vm.draft
    }
  }, [_vm._v("Draft (" + _vm._s(_vm.selection.length) + ")")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }